import React, { useState } from "react"
import { Link } from "gatsby"
import { Modal } from "antd"
import { StaticImage } from "gatsby-plugin-image"
import { initializeHubspotForm } from "../../../../utils/gatsby-helpers"
import { redirectToThankYou } from "../../../../utils/thank-you-access"

const Menu = ({ categories, blog, lang }) => {
    const baseUrl = lang === "en" ? "" : `/${lang}`

    const [showMenu, setShowMenu] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const form = {
        "en": {
            region: "na1",
            portalId: "20249583",
            formId: "4ccd2e79-9836-4b03-a50a-25389d57a194"
        },
        "es": {
            region: "na1",
            portalId: "20249583",
            formId: "a36d099d-9a65-4224-b3ca-337dfb87a90e"
        }
    }

    const thankYouUrl = lang === "es" ? 'suscripcion-contenido' : 'content-suscription'
    initializeHubspotForm(form[lang].portalId, form[lang].formId, "#blog-form-container", form[lang].region, null, () => redirectToThankYou(lang, thankYouUrl))
    return <div className={`nav-collapse ${showMenu ? "nav-collapse--open" : ""}`}>
        <div className="nav-collapse__header">
            <div className="container">
                <span onClick={() => setShowMenu(!showMenu)} className="toggle-button" role="button">{blog.explore} <i className="icon-chevron-right"></i></span>
                <button onClick={() => setShowModal(true)} className="button button--sm">{blog.subscribe}</button>
            </div>
        </div>

        <div className="nav-collapse__body">
            <div className="container">
                <div className="tag-group ai-flex-start">
                    {categories.map((category, index) => {
                        return <Link to={`${baseUrl}/blog/${category.node.url}`} className="tag tag--lg" key={index}>{category.node.name}</Link>
                    })}
                </div>
            </div>
        </div>

        <Modal
            centered
            visible={showModal}
            onCancel={() => setShowModal(false)}
            width={"100%"}
            footer={null}
            className="newsletter"
        >
            <div className="">
                <StaticImage
                    src={"../../../../new/resources/home/newsletter.svg"}
                    title="Man uses tecalis technology solutions"
                    alt="Man uses tecalis technology solutions"
                    quality={100}
                    placeholder="blurred"
                />
            </div>
            <h4>{blog.newsletter.title}</h4>
            <div id="blog-form-container" className="form-hb form-hb--newsletter" />
        </Modal>
    </div>
}

export default Menu
